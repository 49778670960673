import axios from 'axios';
import React, {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import FsLightbox from 'fslightbox-react';
import { Menu, Transition } from '@headlessui/react';
import {
  BellIcon,
  DotsVerticalIcon,
  DownloadIcon,
  TrashIcon,
} from '@heroicons/react/outline';
import { FaSignature } from 'react-icons/fa';

import UploadFileModal from './UploadModal';
import Button from '../../../components/Button';
import classNames from '../../../helpers/classNames';
import useDownloadFile from '../../../hooks/useDownloadFile';
import {
  Bate,
  BateTramit,
  Car,
  Company,
  FILE_TYPE,
  RegistrationTramit,
  Signer,
  TemplateType,
  Transaction,
  Transfer,
  User,
} from '../../../types/types';
import PreviewDocument from './PreviewDocuments';
import { toasterContext } from '../../../context/ToasterContext/ToasterContext';
import {
  editCompany,
  editTransaction,
  editUser,
  editBate,
  editVehicle,
  removeExtraFile,
} from '../../../services/services';
import { fileTypeDictionary } from '../../../enums/fileTypeDictionary';
import DigitallySignModal from '../../../modals/DigitallySignModal/DigitallySignModal';
import DeleteSignatureModal from '../../../modals/DeleteSignatureModal/DeleteSignatureModal';
import { userInfoContext } from '../../../context/UserInfoContext/UserInfoContext';
import ResendSignatureRequestModal from '../../../modals/ResendSignatureRequestModal/ResendSignatureRequestModal';

type Actions = {
  label: string;
  icon: React.ReactElement;
  callback: () => Promise<void>;
};

const fromFileTypeToTemplateSignatureType = (
  fileType: FILE_TYPE,
): TemplateType => {
  switch (fileType) {
    case FILE_TYPE.MANDATE:
      return 'Mandato';
    case FILE_TYPE.SALE_CONTRACT:
      return 'Contrato';
    case FILE_TYPE.MISSPLACEMENT:
      return 'Extravío';
    default:
      return 'Otros';
  }
};

export interface TableActionsProps {
  uri: string;
  fileName: string;
  fileType: FILE_TYPE;
  tramit: Transfer | BateTramit | RegistrationTramit;
  tramitType: 'transaction' | 'bate' | 'registration';
  forceUpdate: () => void;
  actionAppliedTo: Car | User | Company | Transaction;
  canUseDigitalSignature: boolean;
  suzukiDocumentId?: string;
  signatureStatus?: 'PENDING' | 'FINISHED' | 'CANCELLED';
  signers?: Signer[];
  hideAttachementButton?: boolean;
  counterpartType?: 'BUYER' | 'SELLER';
}

function TableActions({
  uri,
  fileName,
  fileType,
  tramit,
  tramitType,
  forceUpdate,
  actionAppliedTo,
  canUseDigitalSignature,
  suzukiDocumentId,
  signatureStatus,
  signers = [],
  hideAttachementButton,
  counterpartType,
}: Readonly<TableActionsProps>): React.ReactElement {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [signatureProvider, setSignatureProvider] = useState<
    'DOCUTEN' | 'DOCUSIGN' | null
  >(null);
  const [digitalSignatureBlobURL, setDigitalSignatureBlobURL] = useState<
    string | null
  >(null);
  const [isSignNewDocumentModalShown, setIsSignNewDocumentModalShown] =
    useState(false);
  const [showPreview, setShowPreview] = useState(false);

  const [
    isResendSignatureRequestModalShown,
    setIsResendSignatureRequestModalShown,
  ] = useState(false);

  const { download } = useDownloadFile();

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);
  const switchPreviewModal = () => setShowPreview((prev) => !prev);

  const { setToasterData } = useContext(toasterContext);
  const { accountInfo } = useContext(userInfoContext);

  const { getAccessTokenSilently } = useAuth0();

  const deleteDocument = async () => {
    const token = await getAccessTokenSilently();
    try {
      switch (fileType) {
        case FILE_TYPE.DOI_BACK: {
          await editUser(token, actionAppliedTo.id, {
            photoDni: [(actionAppliedTo as User)?.photoDni?.[0], null],
          });
          break;
        }
        case FILE_TYPE.DOI_FRONT: {
          await editUser(token, actionAppliedTo.id, {
            photoDni: [null, (actionAppliedTo as User)?.photoDni?.[1]],
          });
          break;
        }
        case FILE_TYPE.CIRCULATION_PERMIT: {
          await editVehicle(token, {
            id: actionAppliedTo.id,
            circulationPermit: null,
          });
          break;
        }
        case FILE_TYPE.TECHNICAL_SHEET:
          await editVehicle(token, {
            id: actionAppliedTo.id,
            technicalSheet: null,
          });
          break;
        case FILE_TYPE.PRO_INVOICE: {
          if (tramitType === 'transaction') {
            await editTransaction(
              token,
              (actionAppliedTo as Transaction).transactionCode,
              {
                proInvoice: null,
              },
            );
          }
          if (tramitType === 'bate') {
            await editBate(token, (actionAppliedTo as Bate).bateCode, {
              proInvoice: null,
            });
          }
          break;
        }
        case FILE_TYPE.SALE_CONTRACT: {
          if (tramitType === 'transaction') {
            await editTransaction(
              token,
              (actionAppliedTo as Transaction).transactionCode,
              {
                saleContract: null,
              },
            );
          }
          if (tramitType === 'bate') {
            await editBate(token, (actionAppliedTo as Bate).bateCode, {
              saleContract: null,
            });
          }
          break;
        }
        case FILE_TYPE.TIF: {
          await editCompany(token, (actionAppliedTo as Company).id, {
            nifFile: null,
          });
          break;
        }
        case FILE_TYPE.MANDATE: {
          await editUser(token, actionAppliedTo.id, {
            mandate: null,
          });
          break;
        }
        case FILE_TYPE.EXTRA_FILES: {
          await removeExtraFile(token, {
            objectKey: uri,
            tramitType,
            tramitId: actionAppliedTo.id,
          });
          break;
        }
        default:
          break;
      }
      setToasterData({
        type: 'SUCCESS',
        title: `Documento ${fileTypeDictionary(
          fileType,
        )} eliminado correctamente`,
        message: 'Se eliminó el documento.',
      });
      forceUpdate();
    } catch (err) {
      setToasterData({
        type: 'ERROR',
        title: 'Error eliminando documento',
        message:
          'Ha ocurrido algún problema eliminando el documento. Intentalo más tarde.',
      });
    }
  };

  const downloadFromSuzuki = async () => {
    const token = await getAccessTokenSilently();
    const response = await axios.get<ArrayBuffer>(uri, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: 'arraybuffer',
    });
    const blob = new Blob([response.data], { type: 'application/pdf' });
    const objectURL = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = objectURL;
    a.target = '_blank';
    a.click();
  };

  const deleteFromSuzuki = async () => {
    setOpenDeleteModal(true);
  };

  const previewDigitalSignature = async () => {
    setIsDisabled(true);
    const token = await getAccessTokenSilently();
    const response = await axios.get<ArrayBuffer>(uri, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: 'arraybuffer',
    });
    const blob = new Blob([response.data], { type: 'application/pdf' });
    const objectURL = URL.createObjectURL(blob);
    setDigitalSignatureBlobURL(objectURL);
    switchPreviewModal();
    setIsDisabled(false);
  };

  const sendNotification = async () => {
    try {
      const token = await getAccessTokenSilently();
      await axios.post(
        `${process.env.REACT_APP_SUZUKI_URL}/documents/notify/${suzukiDocumentId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setToasterData({
        title: 'Notificación enviada',
        message:
          'La notificación ha sido enviada con éxito a las partes que faltan por firmar.',
        type: 'SUCCESS',
      });
    } catch {
      setToasterData({
        title: 'Error notificando',
        message:
          'Ha ocurrido un error al intentar enviar la notificación. Por favor, inténtelo de nuevo más tarde.',
        type: 'ERROR',
      });
    }
  };

  const hasDigitalSignature = Boolean(signatureStatus);

  useEffect(() => {
    const getProvider = async () => {
      if (!hasDigitalSignature) return;
      const token = await getAccessTokenSilently();
      const { provider } = await axios
        .get(
          `${process.env.REACT_APP_SUZUKI_URL}/documents/provider/${accountInfo?.id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then((res) => res.data);
      setSignatureProvider(provider);
    };
    getProvider();
  }, [accountInfo?.id, hasDigitalSignature]);

  const actions: Actions[] = useMemo(
    () =>
      [
        signatureStatus === 'PENDING' && {
          label: 'Reenviar notificación',
          icon: <BellIcon className="w-5 h-5" />,
          callback:
            signatureProvider === 'DOCUTEN'
              ? sendNotification
              : async () => setIsResendSignatureRequestModalShown(true),
        },
        {
          label: 'Descargar',
          icon: <DownloadIcon className="w-5 h-5" />,
          callback: hasDigitalSignature
            ? downloadFromSuzuki
            : async () => download(uri, fileName),
        },
        {
          label: 'Eliminar',
          icon: <TrashIcon className="w-5 h-5" />,
          callback: hasDigitalSignature ? deleteFromSuzuki : deleteDocument,
        },
      ].filter(Boolean),
    [signatureStatus, signatureProvider, hasDigitalSignature, uri, fileName],
  );

  return (
    <>
      <UploadFileModal
        closeModal={closeModal}
        showModal={showModal}
        fileType={fileType}
        actionAppliedTo={actionAppliedTo}
        forceUpdate={forceUpdate}
        tramitType={tramitType}
      />
      <DigitallySignModal
        templateType={fromFileTypeToTemplateSignatureType(fileType)}
        isShown={isSignNewDocumentModalShown}
        hide={() => setIsSignNewDocumentModalShown(false)}
        procedure={tramit}
        forceUpdate={forceUpdate}
        counterpartType={counterpartType}
      />
      <ResendSignatureRequestModal
        procedure={tramit}
        isShown={isResendSignatureRequestModalShown}
        hide={() => setIsResendSignatureRequestModalShown(false)}
        suzukiDocumentId={suzukiDocumentId}
        signers={signers}
      />
      {hasDigitalSignature && (
        <DeleteSignatureModal
          documentId={suzukiDocumentId}
          open={openDeleteModal}
          setOpen={setOpenDeleteModal}
          setToasterData={setToasterData}
          update={forceUpdate}
        />
      )}
      {uri && !hasDigitalSignature && (
        <PreviewDocument uri={uri} toggler={showPreview} />
      )}
      {hasDigitalSignature && (
        <FsLightbox
          toggler={showPreview}
          sources={
            [
              <iframe
                title="pdf"
                src={digitalSignatureBlobURL}
                width="1920px"
                height="1080px"
                allow="autoplay; fullscreen"
                allowFullScreen
              />,
            ] as unknown as string[]
          }
        />
      )}
      <td className="flex justify-end whitespace-nowrap py-4 px-8 text-sm font-medium text-gray-900">
        {uri ? (
          <>
            <Button
              bgColor="bg-white"
              hoverBgColor="bg-gray-100"
              text="Ver"
              textColor="text-gray-900"
              border="border"
              borderColor="border-gray-300"
              disabled={isDisabled}
              callback={
                hasDigitalSignature
                  ? previewDigitalSignature
                  : switchPreviewModal
              }
            />
            <Menu
              as="div"
              className="relative inline-block text-left pt-2 ml-3"
            >
              <div>
                <Menu.Button className="flex items-center" aria-label="Options">
                  <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-8 top-7 -translate-y-full z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    {actions.map((action) => (
                      <Menu.Item key={action.label}>
                        {({ active }) => (
                          <button
                            type="button"
                            className={classNames(
                              active
                                ? 'bg-gray-100 text-gray-900'
                                : 'text-gray-700',
                              'flex px-4 py-2 text-sm w-full items-center justify-start gap-3',
                            )}
                            onClick={action.callback}
                          >
                            {action.icon}
                            {action.label}
                          </button>
                        )}
                      </Menu.Item>
                    ))}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </>
        ) : (
          <>
            {canUseDigitalSignature && (
              <Button
                bgColor="bg-white"
                hoverBgColor="bg-gray-100"
                text="Firmar digitalmente"
                textColor="text-gray-900"
                border="border"
                borderColor="border-gray-300"
                LeftIcon={FaSignature}
                callback={() => setIsSignNewDocumentModalShown(true)}
              />
            )}
            {!hideAttachementButton && (
              <Button
                bgColor="bg-white"
                hoverBgColor="bg-gray-100"
                text="Adjuntar"
                textColor="text-gray-900"
                border="border"
                borderColor="border-gray-300"
                additionalClasses="ml-2"
                callback={openModal}
              />
            )}
          </>
        )}
      </td>
    </>
  );
}

export default TableActions;
