import axios from 'axios';
import { Buffer } from 'buffer';

import {
  Company,
  Transaction,
  User,
  Car,
  Bate,
  DigitalSignature,
} from '../types/types';

export const editTransaction = async (
  token: string,
  transactionCode: string,
  payload: Partial<Transaction>,
) =>
  axios.put(
    `${process.env.REACT_APP_BASE_API_URL}/edit-transaction/${transactionCode}`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

export const editBate = async (
  token: string,
  bateCode: string,
  payload: Partial<Bate>,
) =>
  axios.put(
    `${process.env.REACT_APP_BASE_API_URL}/v1/bate/${bateCode}`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

export const editUser = async (
  token: string,
  userId: string,
  payload: Partial<User>,
): Promise<void> =>
  axios.put(
    `${process.env.REACT_APP_BASE_API_URL}/edit-user/${userId}`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

export const createUser = async (token: string, user: Partial<User>) => {
  const { data } = await axios.post<User>(
    `${process.env.REACT_APP_BASE_API_URL}/create-user`,
    user,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return data;
};

export const editCompany = async (
  token: string,
  companyId: string,
  payload: Partial<Company>,
) =>
  axios.put(
    `${process.env.REACT_APP_BASE_API_URL}/edit-company/id/${companyId}`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

export const createCompany = async (token: string, body: Partial<Company>) => {
  const { data } = await axios.post<Company>(
    `${process.env.REACT_APP_BASE_API_URL}/company`,
    body,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return data;
};

export const editVehicle = async (token: string, vehicle: Partial<Car>) =>
  axios.put(
    `${process.env.REACT_APP_BASE_API_URL}/edit-car/${vehicle.id}`,
    vehicle,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

export const pdfToImg = async (token: string, uri: string): Promise<string> => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_BASE_API_URL}/pdf-to-image`,
    {
      url: uri,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return data;
};

export const getAllIncidentsByEntityId = async (
  token: string,
  entityId: string,
  open?: boolean,
) =>
  axios.get(
    `${process.env.REACT_APP_BASE_API_URL}/incidents/entity/id/${entityId}${
      open ? '?open=true' : ''
    }`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

export const getBateByTransactionId = async (
  token: string,
  transactionId: string,
) =>
  axios.get(
    `${process.env.REACT_APP_BASE_API_URL}/v1/transaction/${transactionId}/bate`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

export const getAggregatedTransaction = async (
  token: string,
  transactionCode: string,
) =>
  axios.get(
    `${process.env.REACT_APP_BASE_API_URL}/get-aggregated-transactions/${transactionCode}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

export const removeExtraFile = async (
  token: string,
  {
    tramitId,
    tramitType,
    objectKey,
  }: {
    tramitId: string;
    tramitType: 'transaction' | 'bate' | 'registration';
    objectKey: string;
  },
) =>
  axios.delete(
    `${process.env.REACT_APP_BASE_API_URL}/remove-extra-file/${tramitType}/${tramitId}/${objectKey}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

export const loadInvoice = async (
  token: string,
  invoiceId: string,
): Promise<string> => {
  const { data: base64Blob } = await axios(
    `${process.env.REACT_APP_BASE_API_URL}/holded-document-pdf?documentType=invoice&documentId=${invoiceId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/pdf',
        'x-origin': 'swipoo-pro',
      },
    },
  );
  const buffer = Buffer.from(base64Blob, 'base64');
  const pdf = new Blob([buffer], {
    type: 'application/pdf',
  });

  return URL.createObjectURL(pdf);
};

export const loadDigitalSignaturesByProcedure = async (
  token: string,
  entityType: 'transaction' | 'bate' | 'registration',
  code: string,
): Promise<DigitalSignature[]> =>
  axios
    .get(
      `${process.env.REACT_APP_SUZUKI_URL}/documents/entity/${entityType}/${code}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((res) => res.data);

export const getS3SignedUrl = async (
  objectKey: string,
  bucketName: string,
  token: string,
) => {
  try {
    const { data } = await axios.get(
      `${
        process.env.REACT_APP_BASE_API_URL
      }/s3-get-file-signed-url/${bucketName}/${encodeURIComponent(objectKey)}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return data.signedUrl;
  } catch (e) {
    return '';
  }
};

export const checkAccountHasPaymentMethod = async (
  accountId: string,
  token: string,
): Promise<boolean> => {
  try {
    await axios.get(
      `${process.env.REACT_APP_BASE_API_URL}/has-payment-method/${accountId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return true;
  } catch (e) {
    return false;
  }
};
