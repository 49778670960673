export default function trimAllStrings(input) {
  if (Array.isArray(input)) {
    return input.map(trimAllStrings);
  }

  if (input === null || typeof input !== 'object') {
    return input;
  }

  return Object.keys(input).reduce((acc, key) => {
    const value = input[key];
    if (typeof value === 'string') {
      acc[key] = value.trim();
    } else {
      acc[key] = trimAllStrings(value);
    }
    return acc;
  }, {});
}
