import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import React, { useContext, useState, useCallback } from 'react';
import { DocumentTextIcon, PaperClipIcon } from '@heroicons/react/outline';
import { CheckCircleIcon } from '@heroicons/react/solid';

import GenerateDocumentationModal from './GenerateDocumentationModal/GenerateDocumentationModal';
import EditorProvisionalModal from '../../../../../components/EditorProvisionalModal';
import FileLink from '../../../../../components/FileLink';
import Spinner from '../../../../../components/Spinner';
import NotifierModal from '../../../../../components/NotifierModal';
import { transferDetailsContext } from '../../../../../context/transfer-details.context';
import useExtraFiles from '../../../../../hooks/useExtraFiles';
import { ENTITY_TYPES } from '../../../../../helpers/enums';
import ExtraFilesDragAndDrop from '../../../../../components/ExtraFilesDragAndDrop';
import TrafficReportSection from './TrafficReportSection';
import DeleteExtraFileModal from '../../../../../components/DeleteExtraFilesModal';
import { useServices } from '../../../../../services';
import trimAllStrings from '../../../../../helpers/trimAllStrings';
import containsNullUndefinedOrEmptyString from '../../../../../helpers/checkNullOrUndefinedOrEmptyString';

export default function DocumentsSection({ setModalType }) {
  const [isProvisionalOpen, setIsProvisionalOpen] = useState(false);
  const [documentationModalOpen, setDocumentationModalOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [hasProcedureA9Error, setHasProcedureA9Error] = useState(false);
  const [hasProcedureA9Success, setHasProcedureA9Success] = useState(false);
  const [isLoadingA9Procedure, setIsLoadingA9Procedure] = useState(false);
  const [hasMissingData, setHasMissingData] = useState(false);

  const { transfer, operationType, trafficReport, forceUpdate } = useContext(
    transferDetailsContext,
  );
  const { transaction, buyer, seller, agent, car } = transfer;
  const { sendProcedureToA9 } = useServices();
  const { getAccessTokenSilently } = useAuth0();

  const {
    extraFilesLoading,
    extraFiles,
    handleViewExtraFile,
    handleDeleteExtraFile,
    handleUploadExtraFiles,
    modalError,
    resetModalError,
  } = useExtraFiles({
    tramitType: ENTITY_TYPES.TRANSACTION,
    entity: transaction,
  });

  const handleSetFileToDelete = (file) => {
    setFileToDelete(file);
  };

  const clearFileToDelete = () => {
    setFileToDelete(null);
  };

  const computeComOperationType = (buyer, seller) => {
    const buyerIsCompany = buyer?.representativeId;
    const sellerIsCompany = seller?.representativeId;

    if (sellerIsCompany && buyerIsCompany) {
      return 'B2B';
    }
    if (buyerIsCompany && !sellerIsCompany) {
      return 'B2P';
    }
    if (!buyerIsCompany && sellerIsCompany) {
      return 'P2B';
    }
    return 'P2P';
  };

  const handleSendTransactionToA9 = useCallback(async () => {
    if (!operationType) return;
    try {
      let data = {};
      if (operationType === 'P2P') {
        data = {
          buyer: {
            dni: buyer.dni,
            sex: buyer.sex,
            name: buyer.name,
            surname: buyer.surname,
            province: buyer.province,
            city: buyer.city,
            birthDate: buyer.birthDate,
            address: buyer.address,
            zipCode: buyer.zipCode,
          },
          seller: {
            dni: seller.dni,
            sex: seller.sex,
            name: seller.name,
            surname: seller.surname,
            province: seller.province,
            city: seller.city,
            birthDate: seller.birthDate,
            address: seller.address,
            zipCode: seller.zipCode,
          },
          car: {
            enrollmentDate: car.enrollmentDate,
            plate: car.plate,
            serviceType: car.serviceType,
            isMoto: car.isMoto,
          },
          agent: {
            nif: agent.nif,
          },
          transaction: {
            environmentBadge: Boolean(transaction.environmentBadge),
            transactionCode: transaction.transactionCode,
            dgtFeeNumber: transaction.dgtFeeNumber,
          },
        };
      } else if (operationType === 'P2B') {
        data = {
          buyer: {
            dni: buyer.dni,
            sex: buyer.sex,
            name: buyer.name,
            surname: buyer.surname,
            province: buyer.province,
            city: buyer.city,
            birthDate: buyer.birthDate,
            address: buyer.address,
            zipCode: buyer.zipCode,
          },
          seller: {
            nif: seller.nif,
            legalName: seller.legalName,
            province: seller.fiscalAddressProvince,
            city: seller.fiscalAddressCity,
            address: seller.fiscalAddressAddress,
            zipCode: seller.fiscalAddressZipCode,
            representative: {
              dni: seller.representative?.dni,
              name: seller.representative?.name,
              surname: seller.representative?.surname,
            },
          },
          car: {
            enrollmentDate: car.enrollmentDate,
            plate: car.plate,
            serviceType: car.serviceType,
            isMoto: car.isMoto,
          },
          agent: {
            nif: agent.nif,
          },
          transaction: {
            environmentBadge: Boolean(transaction.environmentBadge),
            transactionCode: transaction.transactionCode,
            dgtFeeNumber: transaction.dgtFeeNumber,
          },
        };
      } else if (operationType === 'B2P') {
        data = {
          seller: {
            dni: seller.dni,
            sex: seller.sex,
            name: seller.name,
            surname: seller.surname,
            province: seller.province,
            city: seller.city,
            birthDate: seller.birthDate,
            address: seller.address,
            zipCode: seller.zipCode,
          },
          buyer: {
            nif: buyer.nif,
            legalName: buyer.legalName,
            province: buyer.fiscalAddressProvince,
            city: buyer.fiscalAddressCity,
            address: buyer.fiscalAddressAddress,
            zipCode: buyer.fiscalAddressZipCode,
            representative: {
              dni: buyer.representative?.dni,
              name: buyer.representative?.name,
              surname: buyer.representative?.surname,
            },
          },
          car: {
            enrollmentDate: car.enrollmentDate,
            plate: car.plate,
            serviceType: car.serviceType,
            isMoto: car.isMoto,
          },
          agent: {
            nif: agent.nif,
          },
          transaction: {
            environmentBadge: Boolean(transaction.environmentBadge),
            transactionCode: transaction.transactionCode,
            dgtFeeNumber: transaction.dgtFeeNumber,
          },
        };
      } else if (operationType === 'B2B') {
        data = {
          seller: {
            nif: seller.nif,
            legalName: seller.legalName,
            province: seller.fiscalAddressProvince,
            city: seller.fiscalAddressCity,
            address: seller.fiscalAddressAddress,
            zipCode: seller.fiscalAddressZipCode,
            representative: {
              dni: seller.representative?.dni,
              name: seller.representative?.name,
              surname: seller.representative?.surname,
            },
          },
          buyer: {
            nif: buyer.nif,
            legalName: buyer.legalName,
            province: buyer.fiscalAddressProvince,
            city: buyer.fiscalAddressCity,
            address: buyer.fiscalAddressAddress,
            zipCode: buyer.fiscalAddressZipCode,
            representative: {
              dni: buyer.representative?.dni,
              name: buyer.representative?.name,
              surname: buyer.representative?.surname,
            },
          },
          car: {
            enrollmentDate: car.enrollmentDate,
            plate: car.plate,
            serviceType: car.serviceType,
            isMoto: car.isMoto,
          },
          agent: {
            nif: agent.nif,
          },
          transaction: {
            environmentBadge: Boolean(transaction.environmentBadge),
            transactionCode: transaction.transactionCode,
            dgtFeeNumber: transaction.dgtFeeNumber,
          },
        };
      } else {
        throw Error('Invalid Transaction operation type');
      }
      const hasNullOrUndefiend = containsNullUndefinedOrEmptyString(data);
      if (hasNullOrUndefiend) {
        setHasMissingData(true);
        return;
      }
      const trimmedData = trimAllStrings(data);
      setIsLoadingA9Procedure(true);
      await sendProcedureToA9({
        procedureType: ENTITY_TYPES.TRANSACTION,
        operationType,
        data: trimmedData,
      });
      setHasProcedureA9Error(false);
      setHasProcedureA9Success(true);
    } catch {
      setHasProcedureA9Error(true);
    } finally {
      setIsLoadingA9Procedure(false);
    }
  }, [
    operationType,
    seller,
    buyer,
    agent,
    car,
    transaction,
    sendProcedureToA9,
  ]);

  const handleSendComToA9 = useCallback(async () => {
    try {
      setIsLoadingA9Procedure(true);
      const token = await getAccessTokenSilently();
      const { bateCode } = await axios
        .get(
          `${process.env.REACT_APP_BASE_API_URL}/v1/transaction/${transaction.id}/bate`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then((res) => res.data);

      const { seller: bateSeller } = await axios
        .get(`${process.env.REACT_APP_BASE_API_URL}/v1/bate/${bateCode}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => res.data);

      const comOperationType = computeComOperationType(buyer, bateSeller);

      let data = {};
      if (comOperationType === 'P2P') {
        data = {
          buyer: {
            dni: buyer.dni,
            sex: buyer.sex,
            name: buyer.name,
            surname: buyer.surname,
            province: buyer.province,
            city: buyer.city,
            birthDate: buyer.birthDate,
            address: buyer.address,
            zipCode: buyer.zipCode,
          },
          bridge: {
            iae: '6541', // Añadir campo en las empresas
            nif: seller.nif,
            legalName: seller.legalName,
            province: seller.fiscalAddressProvince,
            city: seller.fiscalAddressCity,
            address: seller.fiscalAddressAddress,
            zipCode: seller.fiscalAddressZipCode,
            representative: {
              dni: seller.representative?.dni,
              name: seller.representative?.name,
              surname: seller.representative?.surname,
            },
          },
          seller: {
            dni: bateSeller.dni,
            sex: bateSeller.sex,
            name: bateSeller.name,
            surname: bateSeller.surname,
            province: bateSeller.province,
            city: bateSeller.city,
            birthDate: bateSeller.birthDate,
            address: bateSeller.address,
            zipCode: bateSeller.zipCode,
          },
          car: {
            enrollmentDate: car.enrollmentDate,
            plate: car.plate,
            serviceType: car.serviceType,
            isMoto: car.isMoto,
          },
          agent: {
            nif: agent.nif,
          },
          transaction: {
            environmentBadge: Boolean(transaction.environmentBadge),
            transactionCode: transaction.transactionCode,
            dgtFeeNumber: transaction.dgtFeeNumber,
          },
        };
      } else if (comOperationType === 'P2B') {
        data = {
          buyer: {
            dni: buyer.dni,
            sex: buyer.sex,
            name: buyer.name,
            surname: buyer.surname,
            province: buyer.province,
            city: buyer.city,
            birthDate: buyer.birthDate,
            address: buyer.address,
            zipCode: buyer.zipCode,
          },
          bridge: {
            iae: '6541', // Añadir campo en las empresas
            nif: seller.nif,
            legalName: seller.legalName,
            province: seller.fiscalAddressProvince,
            city: seller.fiscalAddressCity,
            address: seller.fiscalAddressAddress,
            zipCode: seller.fiscalAddressZipCode,
            representative: {
              dni: seller.representative?.dni,
              name: seller.representative?.name,
              surname: seller.representative?.surname,
            },
          },
          seller: {
            nif: bateSeller.nif,
            legalName: bateSeller.legalName,
            province: bateSeller.fiscalAddressProvince,
            city: bateSeller.fiscalAddressCity,
            address: bateSeller.fiscalAddressAddress,
            zipCode: bateSeller.fiscalAddressZipCode,
            representative: {
              dni: bateSeller.representative?.dni,
              name: bateSeller.representative?.name,
              surname: bateSeller.representative?.surname,
            },
          },
          car: {
            enrollmentDate: car.enrollmentDate,
            plate: car.plate,
            serviceType: car.serviceType,
            isMoto: car.isMoto,
          },
          agent: {
            nif: agent.nif,
          },
          transaction: {
            environmentBadge: Boolean(transaction.environmentBadge),
            transactionCode: transaction.transactionCode,
            dgtFeeNumber: transaction.dgtFeeNumber,
          },
        };
      } else if (comOperationType === 'B2P') {
        data = {
          buyer: {
            nif: buyer.nif,
            legalName: buyer.legalName,
            province: buyer.fiscalAddressProvince,
            city: buyer.fiscalAddressCity,
            address: buyer.fiscalAddressAddress,
            zipCode: buyer.fiscalAddressZipCode,
            representative: {
              dni: buyer.representative?.dni,
              name: buyer.representative?.name,
              surname: buyer.representative?.surname,
            },
          },
          bridge: {
            iae: '6541', // Añadir campo en las empresas
            nif: seller.nif,
            legalName: seller.legalName,
            province: seller.fiscalAddressProvince,
            city: seller.fiscalAddressCity,
            address: seller.fiscalAddressAddress,
            zipCode: seller.fiscalAddressZipCode,
            representative: {
              dni: seller.representative?.dni,
              name: seller.representative?.name,
              surname: seller.representative?.surname,
            },
          },
          seller: {
            dni: bateSeller.dni,
            sex: bateSeller.sex,
            name: bateSeller.name,
            surname: bateSeller.surname,
            province: bateSeller.province,
            city: bateSeller.city,
            birthDate: bateSeller.birthDate,
            address: bateSeller.address,
            zipCode: bateSeller.zipCode,
          },
          car: {
            enrollmentDate: car.enrollmentDate,
            plate: car.plate,
            serviceType: car.serviceType,
            isMoto: car.isMoto,
          },
          agent: {
            nif: agent.nif,
          },
          transaction: {
            environmentBadge: Boolean(transaction.environmentBadge),
            transactionCode: transaction.transactionCode,
            dgtFeeNumber: transaction.dgtFeeNumber,
          },
        };
      } else if (comOperationType === 'B2B') {
        data = {
          buyer: {
            nif: buyer.nif,
            legalName: buyer.legalName,
            province: buyer.fiscalAddressProvince,
            city: buyer.fiscalAddressCity,
            address: buyer.fiscalAddressAddress,
            zipCode: buyer.fiscalAddressZipCode,
            representative: {
              dni: buyer.representative?.dni,
              name: buyer.representative?.name,
              surname: buyer.representative?.surname,
            },
          },
          bridge: {
            iae: '6541', // Añadir campo en las empresas
            nif: seller.nif,
            legalName: seller.legalName,
            province: seller.fiscalAddressProvince,
            city: seller.fiscalAddressCity,
            address: seller.fiscalAddressAddress,
            zipCode: seller.fiscalAddressZipCode,
            representative: {
              dni: seller.representative?.dni,
              name: seller.representative?.name,
              surname: seller.representative?.surname,
            },
          },
          seller: {
            nif: bateSeller.nif,
            legalName: bateSeller.legalName,
            province: bateSeller.fiscalAddressProvince,
            city: bateSeller.fiscalAddressCity,
            address: bateSeller.fiscalAddressAddress,
            zipCode: bateSeller.fiscalAddressZipCode,
            representative: {
              dni: bateSeller.representative?.dni,
              name: bateSeller.representative?.name,
              surname: bateSeller.representative?.surname,
            },
          },
          car: {
            enrollmentDate: car.enrollmentDate,
            plate: car.plate,
            serviceType: car.serviceType,
            isMoto: car.isMoto,
          },
          agent: {
            nif: agent.nif,
          },
          transaction: {
            environmentBadge: Boolean(transaction.environmentBadge),
            transactionCode: transaction.transactionCode,
            dgtFeeNumber: transaction.dgtFeeNumber,
          },
        };
      } else {
        throw Error('Invalid COM operation type');
      }
      const hasNullOrUndefiend = containsNullUndefinedOrEmptyString(data);
      if (hasNullOrUndefiend) {
        setHasMissingData(true);
        return;
      }
      await sendProcedureToA9({
        procedureType: 'COM',
        operationType: comOperationType,
        data,
      });
      setHasProcedureA9Error(false);
      setHasProcedureA9Success(true);
    } catch {
      setHasProcedureA9Error(true);
    } finally {
      setIsLoadingA9Procedure(false);
    }
  }, [seller, buyer, agent, car, transaction, sendProcedureToA9]);

  return (
    <>
      <EditorProvisionalModal
        isOpen={isProvisionalOpen}
        setIsOpen={setIsProvisionalOpen}
      />
      <GenerateDocumentationModal
        isOpen={documentationModalOpen}
        setIsOpen={setDocumentationModalOpen}
      />
      <DeleteExtraFileModal
        isOpen={Boolean(fileToDelete)}
        close={clearFileToDelete}
        file={fileToDelete}
        handleDeleteExtraFile={handleDeleteExtraFile}
      />
      <div className="sm:col-span-2">
        <dt className="text-sm font-medium text-gray-500">Documentos</dt>
        <dd className="mt-1 text-sm text-gray-900">
          <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
            <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
              <div className="w-0 flex-1 flex items-center">
                <PaperClipIcon
                  className="shrink-0 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <span className="ml-2 flex-1 w-0 truncate">
                  Contrato de compraventa
                </span>
              </div>
              <div className="ml-4 shrink-0 flex space-x-4">
                {transaction.saleContract ? (
                  <>
                    <FileLink source={transaction?.saleContract} />
                    <span className="text-gray-300" aria-hidden="true">
                      |
                    </span>
                    <button
                      type="button"
                      onClick={() => setModalType('sellBuyContract')}
                      className="rounded-md font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-blue-500"
                    >
                      Reemplazar
                    </button>
                  </>
                ) : (
                  <button
                    type="button"
                    onClick={() => setModalType('sellBuyContract')}
                    className="rounded-md font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-blue-500"
                  >
                    Añadir
                  </button>
                )}
              </div>
            </li>
            {transaction.trafficReport && (
              <TrafficReportSection
                uri={trafficReport}
                forceUpdate={forceUpdate}
                transactionCode={transaction.transactionCode}
              />
            )}
            <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
              <div className="w-0 flex-1 flex items-center">
                <PaperClipIcon
                  className="shrink-0 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <span className="ml-2 flex-1 w-0 truncate">
                  Permiso de circulación provisional
                </span>
              </div>
              <div className="ml-4 shrink-0 flex space-x-4">
                {transaction.temporaryCirculationDoc ? (
                  <>
                    <FileLink source={transaction.temporaryCirculationDoc} />
                    <span className="text-gray-300" aria-hidden="true">
                      |
                    </span>
                    <button
                      type="button"
                      onClick={() => {
                        setIsProvisionalOpen(true);
                      }}
                      className="rounded-md font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-blue-500"
                    >
                      Reemplazar
                    </button>
                  </>
                ) : (
                  <button
                    type="button"
                    onClick={() => {
                      setIsProvisionalOpen(true);
                    }}
                    className="rounded-md font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-blue-500"
                  >
                    Añadir
                  </button>
                )}
              </div>
            </li>
            <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
              <div className="w-0 flex-1 flex items-center">
                <PaperClipIcon
                  className="shrink-0 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <span className="ml-2 flex-1 w-0 truncate">Factura Holded</span>
              </div>
              <div className="ml-4 shrink-0">
                {transaction?.holdedInvoiceId ? (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://app.holded.com/sales/revenue#open:invoice-${transaction.holdedInvoiceId}`}
                    className="font-medium text-blue-600 hover:text-blue-500"
                  >
                    Ver
                  </a>
                ) : (
                  <span className="font-medium text-gray-500 cursor-not-allowed">
                    No Disponible
                  </span>
                )}
              </div>
            </li>
            <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
              <div className="w-0 flex-1 flex items-center">
                <PaperClipIcon
                  className="shrink-0 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <span className="ml-2 flex-1 w-0 truncate">
                  Factura profesional
                </span>
              </div>
              <div className="ml-4 shrink-0 flex space-x-4">
                {transaction.proInvoice ? (
                  <>
                    <FileLink source={transaction.proInvoice} />
                    <span className="text-gray-300" aria-hidden="true">
                      |
                    </span>
                    <button
                      type="button"
                      onClick={() => setModalType('proInvoice')}
                      className="rounded-md font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-blue-500"
                    >
                      Reemplazar
                    </button>
                  </>
                ) : (
                  <button
                    type="button"
                    onClick={() => setModalType('proInvoice')}
                    className="rounded-md font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-blue-500"
                  >
                    Añadir
                  </button>
                )}
              </div>
            </li>
            <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
              <div className="w-0 flex-1 flex items-center">
                <PaperClipIcon
                  className="shrink-0 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <span className="ml-2 flex-1 w-0 truncate">
                  Justificante de presentación (profesional)
                </span>
              </div>
              <div className="ml-4 shrink-0 flex space-x-4">
                {transaction.presentationProof ? (
                  <>
                    <FileLink source={transaction.presentationProof} />
                    <span className="text-gray-300" aria-hidden="true">
                      |
                    </span>
                    <button
                      type="button"
                      onClick={() => setModalType('presentationProof')}
                      className="rounded-md font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-blue-500"
                    >
                      Reemplazar
                    </button>
                  </>
                ) : (
                  <button
                    type="button"
                    onClick={() => setModalType('presentationProof')}
                    className="rounded-md font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-blue-500"
                  >
                    Añadir
                  </button>
                )}
              </div>
            </li>
            <li className="group pl-3 pr-4 py-3 flex flex-col items-center justify-between text-sm">
              <div className="flex items-center justify-between w-full">
                <div className="w-0 flex-1 flex items-center">
                  <PaperClipIcon
                    className="shrink-0 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <span className="ml-2 flex-1 w-0 truncate">
                    Archivos extra
                  </span>
                  {extraFilesLoading && (
                    <Spinner color="text-blue-700" size="4" />
                  )}
                </div>
              </div>
              {extraFiles?.length > 0 && (
                <div className="flex items-center justify-between w-full">
                  <ul className="w-full pl-5 mt-3">
                    {extraFiles.map((file) => (
                      <li
                        key={file}
                        className="flex items-center justify-between w-full p-2 "
                      >
                        <div className="w-0 flex-1 flex items-center">
                          <span className="ml-2 flex-1 truncate">
                            {file?.split('-hash-')[0]}
                          </span>
                        </div>
                        <div className="ml-4 shrink-0">
                          <button
                            type="button"
                            onClick={() => handleViewExtraFile(file)}
                            className="cursor-pointer font-medium text-blue-600 hover:text-blue-500"
                          >
                            Ver
                          </button>
                          <button
                            type="button"
                            onClick={() => handleSetFileToDelete(file)}
                            className="ml-3 cursor-pointer font-medium text-red-600 hover:text-red-500"
                          >
                            Eliminar
                          </button>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {modalError && (
                <NotifierModal
                  text="EL archivo no puede pesar más de 15MB, tiene un nombre más largo de 100 carácteres o no es un formato aceptado (pdf, jpg, png, jpeg, tif, tiff, webp, heic)."
                  buttonText="Ok"
                  onClick={resetModalError}
                />
              )}
              <ExtraFilesDragAndDrop
                handleUploadExtraFiles={handleUploadExtraFiles}
              />
            </li>
          </ul>
          <div className="flex items-center mt-4">
            <button
              type="button"
              className="text-white bg-blue-500 hover:bg-blue-600 rounded-md px-4 py-1 text-lg flex items-center gap-2 mr-4"
              onClick={() => setDocumentationModalOpen(true)}
            >
              Documentación gestoría <DocumentTextIcon className="h-6" />
            </button>
            <button
              type="button"
              disabled={isLoadingA9Procedure}
              className="text-white bg-[#CD8383] hover:bg-[#C67070] rounded-md px-4 py-1 text-lg flex items-center gap-2"
              onClick={
                transaction.isBatecom
                  ? handleSendComToA9
                  : handleSendTransactionToA9
              }
            >
              {isLoadingA9Procedure ? 'Enviando...' : 'Enviar trámite al A9'}{' '}
              <img
                alt="logo A9"
                src="https://static.a9trafico.com/static/img/a9_logo.51283c3f500c.png"
                className="h-6"
              />
            </button>
            {hasProcedureA9Error && (
              <span className="ml-2 text-sm text-red-600">
                Error enviando trámite al A9
              </span>
            )}
            {hasMissingData && (
              <span className="ml-2 text-sm text-red-600">
                Faltan datos por completar
              </span>
            )}
            {hasProcedureA9Success && (
              <span className="flex items-center ml-2 text-sm text-green-600">
                Enviado correctamente
                <CheckCircleIcon className="ml-2 h-6 text-green-600" />
              </span>
            )}
          </div>
        </dd>
      </div>
    </>
  );
}
