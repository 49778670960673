import { useEffect, useState, useReducer, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import {
  BadgeCheckIcon,
  PaperClipIcon,
  ExclamationIcon,
} from '@heroicons/react/solid';
import { Tooltip } from 'react-tooltip';

import { parseDate } from '../../../helpers';
import isUnderAge from '../../../helpers/isUnderAge';
import useIsValidZipCode from '../../../hooks/useIsValidZipCode';
import { isValidDNI, isValidNIE } from '../../../helpers/doiValidators';
import isDoiCloseToExpire from '../../../helpers/isDoiCloseToExpire';
import { useServices } from '../../../services';
import { asyncState, ccaaDictionary } from '../../../helpers/enums';
import EditorModal from '../../../components/EditorModal';
import AssociatedTramits from '../../../components/AssociatedTramits';
import FileLink from '../../../components/FileLink';
import Spinner from '../../../components/Spinner';
import { obtainZipCodeByAddress } from '../../../helpers/obtainZipCodeByAddress';
import { obtainProvinceByAddress } from '../../../helpers/obtainProvinceByAddress';
import AssociatedCompanies from './components/AssociatedCompanies';
import UploadFileModal from '../../../components/UploadFileModal';

export default function CustomerDetail() {
  const [customer, setCustomer] = useState(null);
  const [modalType, setModalType] = useState(null);
  const [uploadModalType, setUploadModalType] = useState('');
  const [updater, forceUpdate] = useReducer((x) => x + 1, 0);
  const [zipCodeState, setZipCodeState] = useState(asyncState.INITIAL);
  const [provinceState, setProvinceState] = useState(asyncState.INITIAL);

  const { userId } = useParams();
  const { getUserById, editUser, getEntityTramitsUser } = useServices();

  const { isValidZipCode } = useIsValidZipCode(customer?.zipCode);

  const fetchUser = useCallback(async () => {
    const user = await getUserById(userId);
    setCustomer(user);
  }, [getUserById, userId]);

  useEffect(() => {
    setZipCodeState(asyncState.INITIAL);
    fetchUser();
  }, [fetchUser, updater]);

  const handleObtainZipCode = async () => {
    try {
      setZipCodeState(asyncState.LOADING);

      const address = `${customer.address} ${
        customer.city && `, ${customer.city}`
      }`;

      const zipCode = await obtainZipCodeByAddress(address);

      if (!zipCode) {
        return setZipCodeState(asyncState.NOT_FOUND);
      }

      await editUser(userId, { zipCode });

      forceUpdate();

      return setZipCodeState(asyncState.SUCCESS);
    } catch (error) {
      return setZipCodeState(asyncState.ERROR);
    }
  };

  const handleObtainProvince = async () => {
    try {
      setProvinceState(asyncState.LOADING);

      const address = `${customer.address} ${
        customer.city && `, ${customer.city}`
      }`;

      const province = await obtainProvinceByAddress(address);

      if (!province) {
        return setProvinceState(asyncState.NOT_FOUND);
      }

      await editUser(userId, { province });

      forceUpdate();

      return setProvinceState(asyncState.SUCCESS);
    } catch (error) {
      return setProvinceState(asyncState.ERROR);
    }
  };

  const getCustomerSex = (sex) => {
    if (sex === 'M') return 'Varón';
    if (sex === 'F') return 'Mujer';
    return '';
  };

  return (
    customer && (
      <>
        <UploadFileModal
          entity={customer}
          modalType={uploadModalType}
          setModalType={setUploadModalType}
          entityKey={customer?.id}
          editEntity={editUser}
          forceUpdate={forceUpdate}
        />
        <EditorModal
          type={modalType}
          setType={setModalType}
          id={userId}
          save={forceUpdate}
          preloadedState={customer}
        />
        <div className="flex justify-between">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900 inline">
              {customer.name} {customer.surname}
            </h3>
            <button
              type="button"
              onClick={() => setModalType('name')}
              className="ml-1 rounded-md text-xs text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-indigo-500"
            >
              editar
            </button>
            <br />
            <p className="mt-1 max-w-2xl text-sm text-gray-500 inline">
              {customer.email}
            </p>
            <button
              type="button"
              onClick={() => setModalType('email')}
              className="ml-2 rounded-md text-xs text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-indigo-500"
            >
              editar
            </button>
          </div>
          <div>
            {customer.holdedId && (
              <span className="ml-4 shrink-0">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://app.holded.com/contacts/${customer.holdedId}`}
                  className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-indigo-500"
                >
                  Ir a Holded
                </a>
              </span>
            )}
            {customer.stripeId && (
              <span className="ml-4 shrink-0">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://dashboard.stripe.com/customers/${customer.stripeId}`}
                  className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-indigo-500"
                >
                  Ir a Stripe
                </a>
              </span>
            )}
          </div>
        </div>
        <div className="mt-3 border-t border-gray-200">
          <dl className="divide-y divide-gray-200">
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium text-gray-500">
                Teléfono móbil
              </dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <span className="flex-grow flex gap-3 items-center">
                  {customer.phone}
                </span>
                <span className="ml-4 shrink-0">
                  <button
                    type="button"
                    onClick={() => setModalType('phone')}
                    className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-indigo-500"
                  >
                    Editar
                  </button>
                </span>
              </dd>
            </div>
            <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium text-gray-500">Domicilio</dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <span className="flex-grow">
                  {customer.address}
                  {customer.city && `, ${customer.city}`}
                </span>
                <span className="ml-4 shrink-0">
                  <button
                    type="button"
                    onClick={() => setModalType('address')}
                    className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-indigo-500"
                  >
                    Editar
                  </button>
                </span>
              </dd>
            </div>
            <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium text-gray-500">Provincia</dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div className="flex flex-col flex-grow">
                  <span className="flex-grow">{customer.province}</span>
                  {provinceState === asyncState.ERROR && (
                    <p className="text-red-500 text-xs">
                      Hubo un error al buscar la provincia
                    </p>
                  )}
                </div>
                <span className="ml-4 shrink-0 flex gap-x-2">
                  <button
                    type="button"
                    onClick={handleObtainProvince}
                    className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-indigo-500"
                    disabled={provinceState === asyncState.LOADING}
                  >
                    Obtener
                  </button>
                  {provinceState === asyncState.LOADING && (
                    <Spinner color="text-blue-700" size={5} marginTop={0} />
                  )}
                </span>
                <span className="ml-4 shrink-0">
                  <button
                    type="button"
                    onClick={() => setModalType('province')}
                    className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-indigo-500"
                  >
                    Editar
                  </button>
                </span>
              </dd>
            </div>
            <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium text-gray-500">
                Código Postal
              </dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <span className="flex-grow">{customer.zipCode}</span>
                {customer.zipCode && (
                  <span className="flex-grow">
                    {isValidZipCode ? (
                      <BadgeCheckIcon className="text-teal-500 h-6" />
                    ) : (
                      <ExclamationIcon className="text-red-500 h-6" />
                    )}
                  </span>
                )}
                {zipCodeState === asyncState.ERROR && (
                  <p className="text-red-500 text-xs">
                    Hubo un error al buscar el código postal
                  </p>
                )}
                <span className="ml-4 shrink-0 flex gap-x-2">
                  <button
                    type="button"
                    onClick={handleObtainZipCode}
                    className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-indigo-500"
                    disabled={zipCodeState === asyncState.LOADING}
                  >
                    Obtener
                  </button>
                  {zipCodeState === asyncState.LOADING && (
                    <Spinner color="text-blue-700" size={5} marginTop={0} />
                  )}
                </span>
                <span className="ml-4 shrink-0">
                  <button
                    type="button"
                    onClick={() => setModalType('zipCode')}
                    className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-indigo-500"
                  >
                    Editar
                  </button>
                </span>
              </dd>
            </div>
            <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium text-gray-500">
                Comunidad Autónoma
              </dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <span className="flex-grow">
                  {ccaaDictionary[customer.ccaa]}
                </span>
                <span className="ml-4 shrink-0">
                  <button
                    type="button"
                    onClick={() => setModalType('ccaa')}
                    className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-indigo-500"
                  >
                    Editar
                  </button>
                </span>
              </dd>
            </div>
            <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium text-gray-500">DNI</dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <span className="flex-grow">{customer.dni}</span>
                {customer.dni && (
                  <span className="flex-grow">
                    {isValidDNI(customer.dni) || isValidNIE(customer.dni) ? (
                      <BadgeCheckIcon className="text-teal-500 h-6" />
                    ) : (
                      <ExclamationIcon className="text-red-500 h-6" />
                    )}
                  </span>
                )}
                <span className="ml-4 shrink-0">
                  <button
                    type="button"
                    onClick={() => setModalType('dni')}
                    className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-indigo-500"
                  >
                    Editar
                  </button>
                </span>
              </dd>
            </div>
            <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium text-gray-500">
                Fecha de caducidad (DNI)
              </dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <span className="flex-grow">
                  {parseDate(customer.dniExpiryDate)}
                </span>
                {customer.dniExpiryDate &&
                  isDoiCloseToExpire(customer.dniExpiryDate) && (
                    <span className="flex-grow">
                      <ExclamationIcon className="text-yellow-500 h-6" />
                    </span>
                  )}
                <span className="ml-4 shrink-0">
                  <button
                    type="button"
                    onClick={() => setModalType('dniExpiryDate')}
                    className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-indigo-500"
                  >
                    Editar
                  </button>
                </span>
              </dd>
            </div>
            <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium text-gray-500">
                Fecha de nacimiento
              </dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <span className="flex-grow">
                  {parseDate(customer.birthDate)}
                </span>
                {customer.birthDate && isUnderAge(customer.birthDate) && (
                  <span className="flex-grow">
                    <Tooltip
                      id="warning-under-age"
                      style={{
                        marginTop: '0',
                        fontSize: '15px',
                      }}
                      content="Menor de edad"
                      place="top"
                    />
                    <ExclamationIcon
                      data-tooltip-id="warning-under-age"
                      className="text-yellow-500 h-6"
                    />
                  </span>
                )}
                <span className="ml-4 shrink-0">
                  <button
                    type="button"
                    onClick={() => setModalType('birthDate')}
                    className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-indigo-500"
                  >
                    Editar
                  </button>
                </span>
              </dd>
            </div>
            <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium text-gray-500">Sexo (DNI)</dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <span className="flex-grow">
                  {getCustomerSex(customer.sex)}
                </span>
                <span className="ml-4 shrink-0">
                  <button
                    type="button"
                    onClick={() => setModalType('sex')}
                    className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-indigo-500"
                  >
                    Editar
                  </button>
                </span>
              </dd>
            </div>
            <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium text-gray-500">Documentos</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                  <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                    <div className="w-0 flex-1 flex items-center">
                      <PaperClipIcon
                        className="shrink-0 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="ml-2 flex-1 w-0 truncate">
                        DNI - cara
                      </span>
                    </div>
                    <div className="ml-4 shrink-0 flex space-x-4">
                      {customer.photoDni && customer.photoDni[0] ? (
                        <>
                          <FileLink source={customer.photoDni[0]} />
                          <span className="text-gray-300" aria-hidden="true">
                            |
                          </span>
                          <button
                            type="button"
                            onClick={() => setUploadModalType('dniFront')}
                            className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-indigo-500"
                          >
                            Reemplazar
                          </button>
                        </>
                      ) : (
                        <button
                          type="button"
                          onClick={() => setUploadModalType('dniFront')}
                          className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-indigo-500"
                        >
                          Añadir
                        </button>
                      )}
                    </div>
                  </li>
                  <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                    <div className="w-0 flex-1 flex items-center">
                      <PaperClipIcon
                        className="shrink-0 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="ml-2 flex-1 w-0 truncate">
                        DNI - reverso
                      </span>
                    </div>
                    <div className="ml-4 shrink-0 flex space-x-4">
                      {customer.photoDni && customer.photoDni[1] ? (
                        <>
                          <FileLink source={customer.photoDni[1]} />
                          <span className="text-gray-300" aria-hidden="true">
                            |
                          </span>
                          <button
                            type="button"
                            onClick={() => setUploadModalType('dniBack')}
                            className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-indigo-500"
                          >
                            Reemplazar
                          </button>
                        </>
                      ) : (
                        <button
                          type="button"
                          onClick={() => setUploadModalType('dniBack')}
                          className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-indigo-500"
                        >
                          Añadir
                        </button>
                      )}
                    </div>
                  </li>
                  <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                    <div className="w-0 flex-1 flex items-center">
                      <PaperClipIcon
                        className="shrink-0 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="ml-2 flex-1 w-0 truncate">Padrón</span>
                    </div>
                    <div className="ml-4 shrink-0 flex space-x-4">
                      {customer.census ? (
                        <>
                          <FileLink source={customer.census} />
                          <span className="text-gray-300" aria-hidden="true">
                            |
                          </span>
                          <button
                            type="button"
                            onClick={() => setUploadModalType('census')}
                            className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-indigo-500"
                          >
                            Reemplazar
                          </button>
                        </>
                      ) : (
                        <button
                          type="button"
                          onClick={() => setUploadModalType('census')}
                          className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-indigo-500"
                        >
                          Añadir
                        </button>
                      )}
                    </div>
                  </li>
                  <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                    <div className="w-0 flex-1 flex items-center">
                      <PaperClipIcon
                        className="shrink-0 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="ml-2 flex-1 w-0 truncate">Mandato</span>
                    </div>
                    <div className="ml-4 shrink-0 flex space-x-4">
                      {customer.mandate ? (
                        <>
                          <FileLink source={customer.mandate} />
                          <span className="text-gray-300" aria-hidden="true">
                            |
                          </span>
                          <button
                            type="button"
                            onClick={() => setUploadModalType('mandate')}
                            className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-indigo-500"
                          >
                            Reemplazar
                          </button>
                        </>
                      ) : (
                        <button
                          type="button"
                          onClick={() => setUploadModalType('mandate')}
                          className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-indigo-500"
                        >
                          Añadir
                        </button>
                      )}
                    </div>
                  </li>
                </ul>
              </dd>
            </div>
          </dl>
        </div>
        <AssociatedCompanies userId={userId} />
        <AssociatedTramits
          entityId={customer?.id}
          entityIdentifier={customer?.dni}
          fetchFunction={getEntityTramitsUser}
        />
      </>
    )
  );
}
