import React, { Dispatch, SetStateAction, useContext } from 'react';
import { MdArrowBack, MdArrowForward } from 'react-icons/md';
import {
  AsyncState,
  BateTramit,
  RegistrationTramit,
  Transfer,
  TemplateType as TemplateTypes,
} from '../../../../../types/types';
import { Template as TemplateType } from '../ChooseTemplateStep';
import Template from './Template';
import { digitallySignContext } from '../../../../../context/DigitallySignContext/DigitallySignContext';
import { toasterContext } from '../../../../../context/ToasterContext/ToasterContext';
import { DigitallySignSteps } from '../../DigitallySignStepsManager';
import classNames from '../../../../../helpers/classNames';
import Spinner from '../../../../../components/Spinner';

type Props = Readonly<{
  templates: AsyncState<TemplateType[]>;
  selectedTemplateId: string;
  setSelectedTemplateId: Dispatch<SetStateAction<string>>;
  setSelectedDocId: Dispatch<SetStateAction<string | null>>;
  procedure: Transfer | BateTramit | RegistrationTramit;
  injectTemplate: (
    tramit: Transfer | BateTramit | RegistrationTramit,
  ) => Promise<string>;
  injectedDocumentId: AsyncState<string>;
  readonly counterpartType: 'SELLER' | 'BUYER';
  readonly templateType: TemplateTypes;
}>;

const filterTemplatesByCounterPart = (
  templates: TemplateType[],
  templateType: TemplateTypes,
  counterpartType: 'SELLER' | 'BUYER',
): TemplateType[] => {
  const dict = {
    SELLER: 'Vendedor',
    BUYER: 'Comprador',
  };
  if (templateType === 'Mandato') {
    return templates.filter((t) => t.name.includes(dict[counterpartType]));
  }
  return templates;
};

export default function TemplatePicker({
  templates,
  selectedTemplateId,
  setSelectedTemplateId,
  setSelectedDocId,
  procedure,
  injectTemplate,
  injectedDocumentId,
  counterpartType,
  templateType,
}: Props) {
  const { setCurrentDigitallySignStep } = useContext(digitallySignContext);
  const { setToasterData } = useContext(toasterContext);

  const handleNextStep = async () => {
    const newDocumentId = await injectTemplate(procedure);

    if (!newDocumentId) {
      setToasterData({
        title: 'Error generando documento',
        message: 'Ha ocurrido un error generando el documento',
        type: 'ERROR',
      });
      return;
    }

    setSelectedDocId(newDocumentId);

    setCurrentDigitallySignStep(DigitallySignSteps.EDIT_GENERATED_DOCUMENT);
  };

  const handlePreviousStep = () => {
    setCurrentDigitallySignStep(DigitallySignSteps.CHOOSE_DOCUMENT_SOURCE);
  };

  return (
    <section
      className={classNames(
        'flex flex-col h-full justify-between gap-10',
        selectedTemplateId ? 'w-1/2' : 'w-[700px]',
      )}
    >
      <header className="flex flex-col gap-3">
        <h3 className="text-4xl font-bold text-zinc-700">Elegir plantilla</h3>
      </header>
      {templates.isLoading && (
        <article className="flex flex-col gap-4">
          <div className="w-full h-24 bg-zinc-200 rounded-lg text-transparent animate-pulse" />
          <div className="w-full h-24 bg-zinc-200 rounded-lg text-transparent animate-pulse" />
          <div className="w-full h-24 bg-zinc-200 rounded-lg text-transparent animate-pulse" />
        </article>
      )}
      {templates.error && (
        <span className="text-red-500">{templates.error}</span>
      )}
      {!templates.data.length && !templates.isLoading && !templates.error ? (
        <article className="flex items-center justify-center">
          <span>No se encontraron plantillas</span>
        </article>
      ) : (
        <section className="flex flex-col gap-4 h-full p-1 overflow-y-auto">
          {Boolean(templates.data.length) &&
            !templates.isLoading &&
            filterTemplatesByCounterPart(
              templates.data,
              templateType,
              counterpartType,
            ).map((template) => (
              <Template
                key={template?.id}
                template={template}
                previewTemplateId={selectedTemplateId}
                setPreviewTemplateId={setSelectedTemplateId}
              />
            ))}
        </section>
      )}
      <footer className="w-full flex justify-between items-center gap-8">
        <button
          type="button"
          onClick={handlePreviousStep}
          className="flex items-center gap-1.5 px-3 py-2 hover:bg-zinc-100 rounded-lg active:bg-gray-200"
        >
          <MdArrowBack className="size-5" />
          Atrás
        </button>
        <button
          type="button"
          disabled={!selectedTemplateId || injectedDocumentId.isLoading}
          onClick={handleNextStep}
          className={classNames(
            'grid place-items-center px-3 py-2 bg-blue-800 rounded-lg text-white disabled:bg-zinc-100 disabled:cursor-not-allowed',
            selectedTemplateId &&
              !injectedDocumentId.isLoading &&
              'hover:bg-blue-900 active:bg-blue-950',
          )}
        >
          <span
            className={classNames(
              'w-full flex justify-center items-center gap-1.5',
              injectedDocumentId.isLoading && 'invisible',
            )}
          >
            Siguiente
            <MdArrowForward className="size-5" />
          </span>
          {injectedDocumentId.isLoading && (
            <Spinner
              extraClasses="absolute"
              color="text-blue-700"
              size={6}
              marginTop={0}
            />
          )}
        </button>
      </footer>
    </section>
  );
}
