import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

export default function useDownloadFile() {
  const { getAccessTokenSilently } = useAuth0();

  const handleDownload = async (source: string, fileName?: string) => {
    let parsedSource;
    if (/https?:\/\//.test(source)) {
      parsedSource = source;
    } else {
      const token = await getAccessTokenSilently();
      const { data } = await axios.get(
        `${
          process.env.REACT_APP_BASE_API_URL
        }/s3-get-file-signed-url/swipoo-transactions-files/${encodeURIComponent(
          source,
        )}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      parsedSource = data.signedUrl;
    }

    const downloadElement = document.createElement('a');
    downloadElement.target = '_blank';
    downloadElement.rel = 'noopner noreferrer';
    downloadElement.href = parsedSource;
    downloadElement.download = fileName;
    downloadElement.click();
  };

  return {
    download: handleDownload,
  };
}
