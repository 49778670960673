/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useEffect, useMemo, useReducer, useState } from 'react';
import { validate as validateUUID } from 'uuid';
import { useNavigate, useParams } from 'react-router-dom';
import { useServices } from '../services';

export const transferDetailsContext = createContext({
  transfer: {
    transaction: {},
    buyer: {},
    seller: {},
    intermediary: {},
    car: {},
    labels: [],
    agent: {},
  },
  setTransfer: () => {},
  transferLoading: false,
  updater: 0,
  forceUpdate: () => {},
  trafficReport: null,
  transactionIncidents: [],
  operationType: '',
});

export function TransferDetailsContextProvider({ children }) {
  const [transfer, setTransfer] = useState({
    transaction: {},
    buyer: {},
    seller: {},
    intermediary: {},
    car: {},
    labels: [],
    agent: {},
  });
  const [transferLoading, setTransferLoading] = useState(true);

  const [trafficReport, setTrafficReport] = useState(null);
  const [transactionIncidents, setTransactionIncidents] = useState([]);

  const [updater, forceUpdate] = useReducer((x) => x + 1, 0);

  const { code } = useParams();

  const {
    getTransactionByCode,
    getTrafficReportById,
    fetchAllIncidentsByEntityId,
    getTransactionById,
  } = useServices();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchTransaction = async () => {
      if (validateUUID(code)) {
        const transaction = await getTransactionById(code);
        navigate(`/transfers/${transaction.transactionCode}`);
        return;
      }

      const response = await getTransactionByCode(code);

      const incidents = await fetchAllIncidentsByEntityId(
        response?.transaction?.id,
      );
      setTransactionIncidents(incidents);

      if (
        response.transaction &&
        response.transaction.trafficReport &&
        response.transaction.trafficReportId
      ) {
        const { uri } = await getTrafficReportById(
          response.transaction.trafficReportId,
        );
        setTrafficReport(uri);
      }
      setTransfer(response);
      setTransferLoading(false);
    };
    fetchTransaction();
  }, [code, updater]);

  const computeOperationType = (transfer) => {
    const buyerIsCompany = transfer.buyer?.representativeId;
    const sellerIsCompany = transfer.seller?.representativeId;

    if (sellerIsCompany && buyerIsCompany) {
      return 'B2B';
    }
    if (buyerIsCompany && !sellerIsCompany) {
      return 'B2P';
    }
    if (!buyerIsCompany && sellerIsCompany) {
      return 'P2B';
    }
    return 'P2P';
  };

  const value = useMemo(
    () => ({
      transfer,
      setTransfer,
      updater,
      forceUpdate,
      transferLoading,
      trafficReport,
      transactionIncidents,
      operationType: computeOperationType(transfer),
    }),
    [
      transfer,
      setTransfer,
      updater,
      forceUpdate,
      transferLoading,
      trafficReport,
      transactionIncidents,
    ],
  );
  return (
    <transferDetailsContext.Provider value={value}>
      {children}
    </transferDetailsContext.Provider>
  );
}
