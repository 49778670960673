import { months } from './enums';

export const parseDate = (date) =>
  date?.split('T')[0].split('-').reverse().join('/');

const padNum = (num) => num.toString().padStart(2, '0');
const finalPartYear = (num) => num.toString().slice(2, 4);

const processDate = (date) => {
  const d = new Date(date);
  const hours = d.getUTCHours();
  const minutes = d.getUTCMinutes();
  const seconds = d.getUTCSeconds();
  const day = d.getUTCDate();
  const month = d.getUTCMonth() + 1;
  const year = d.getUTCFullYear();

  return { hours, minutes, seconds, day, month, year };
};

export const parseDateHour = (date) => {
  const { day, hours, minutes, month } = processDate(date);
  return `${padNum(hours)}:${padNum(minutes)} ${padNum(day)}/${padNum(month)} `;
};

export const parseDateHourYear = (date) => {
  const { day, hours, minutes, month, year } = processDate(date);
  return `${padNum(hours)}:${padNum(minutes)} ${padNum(day)}/${padNum(
    month,
  )}/${year} `;
};

export const parseDateComplete = (date) => {
  const { day, hours, minutes, month, seconds } = processDate(date);
  return `${padNum(day)}/${padNum(month)} ${padNum(hours)}:${padNum(
    minutes,
  )}:${padNum(seconds)}`;
};

export const parseEventDate = (date) => {
  const { day, hours, minutes, month } = processDate(date);
  return `${months[month - 1]} ${day} ${padNum(hours)}:${padNum(minutes)}`;
};

export const parseEventDateWithYear = (date) => {
  const { day, hours, minutes, month, year } = processDate(date);
  return `${months[month - 1]} ${day}/${finalPartYear(year)} ${padNum(
    hours,
  )}:${padNum(minutes)}`;
};

export const parseNotificationDate = (date) => {
  const { day, hours, minutes, month } = processDate(date);
  return `${padNum(hours)}:${padNum(minutes)} ${day}/${months[month - 1]}`;
};

export const parseDayMonthYear = (date) => {
  const { day, month, year } = processDate(date);
  return `${padNum(day)}:${padNum(month)}/${year}`;
};

export const parseDayMonthYearWithSlash = (date) => {
  const { day, month, year } = processDate(date);
  return `${padNum(day)}/${padNum(month)}/${year}`;
};

export const classNames = (...classes) => classes.filter(Boolean).join(' ');
