import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import FsLightbox from 'fslightbox-react';

function PreviewDocument({
  uri,
  toggler,
  forcePdf,
}: Readonly<{
  uri: string;
  toggler: boolean;
  forcePdf?: boolean;
}>) {
  const [source, setSource] = useState<string | null>(null);

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const getFinalSource = async () => {
      if (/https?:\/\//.test(uri)) {
        setSource(uri);
      } else {
        const token = await getAccessTokenSilently();
        const response = await axios.get(
          `${
            process.env.REACT_APP_BASE_API_URL
          }/s3-get-file-signed-url/swipoo-transactions-files/${encodeURIComponent(
            uri,
          )}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        setSource(response.data.signedUrl);
      }
    };
    getFinalSource();
  }, []);

  if (!source) return null;

  const isPdf =
    source?.includes('.pdf') || source?.includes('.PDF') || forcePdf;

  return (
    <FsLightbox
      toggler={toggler}
      sources={
        [
          isPdf ? (
            <iframe
              title="pdf"
              src={source}
              width="1920px"
              height="1080px"
              allow="autoplay; fullscreen"
              allowFullScreen
            />
          ) : (
            source
          ),
        ] as unknown as string[]
      }
      types={isPdf ? undefined : ['image']}
    />
  );
}

export default PreviewDocument;
