/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useEffect, useMemo, useReducer, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { validate as validateUUID } from 'uuid';

import { useServices } from '../services';

export const bateDetailsContext = createContext({
  aggregatedBate: {
    bate: {},
    buyer: {},
    seller: {},
    car: {},
    labels: [],
    agent: {},
  },
  setAggregatedBate: () => {},
  bateLoading: false,
  updater: 0,
  forceUpdate: () => {},
  bateIncidents: [],
  operationType: '',
});

export function BateDetailsContextProvider({ children }) {
  const [aggregatedBate, setAggregatedBate] = useState({
    bate: {},
    buyer: {},
    seller: {},
    car: {},
    labels: [],
    agent: {},
  });
  const [bateLoading, setBateLoading] = useState(true);

  const [bateIncidents, setBateIncidents] = useState([]);

  const [updater, forceUpdate] = useReducer((x) => x + 1, 0);

  const { code } = useParams();

  const { getBateByCode, fetchAllIncidentsByEntityId, getBateById } =
    useServices();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchBate = async () => {
      if (validateUUID(code)) {
        const bate = await getBateById(code);
        navigate(`/bates/${bate.bateCode}`);
        return;
      }

      const response = await getBateByCode(code);

      const incidents = await fetchAllIncidentsByEntityId(response?.bate?.id);
      setBateIncidents(incidents);

      setAggregatedBate(response);
      setBateLoading(false);
    };
    fetchBate();
  }, [code, updater]);

  const computeOperationType = (bate) => {
    const buyerIsCompany = bate.buyer?.representativeId;
    const sellerIsCompany = bate.seller?.representativeId;

    if (sellerIsCompany && buyerIsCompany) {
      return 'B2B';
    }
    if (buyerIsCompany && !sellerIsCompany) {
      return 'B2P';
    }
    if (!buyerIsCompany && sellerIsCompany) {
      return 'P2B';
    }
    return 'P2P';
  };

  const value = useMemo(
    () => ({
      aggregatedBate,
      setAggregatedBate,
      updater,
      forceUpdate,
      bateLoading,
      bateIncidents,
      operationType: computeOperationType(aggregatedBate),
    }),
    [
      aggregatedBate,
      setAggregatedBate,
      updater,
      forceUpdate,
      bateLoading,
      bateIncidents,
    ],
  );
  return (
    <bateDetailsContext.Provider value={value}>
      {children}
    </bateDetailsContext.Provider>
  );
}
