import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState, useMemo } from 'react';

import FsLightbox from 'fslightbox-react';
import Spinner from './Spinner';
import { pdfToImg } from '../services/services';
import { FILE_TYPE } from '../types/types';

export default function Thumbnail({
  uri,
  alt,
  type,
}: Readonly<{
  uri: string;
  alt: string;
  type: FILE_TYPE;
}>) {
  const [showPreview, setShowPreview] = useState(false);
  const [parsedSource, setParsedSource] = useState(null);
  const [pdfThumbnail, setPdfThumbnail] = useState(null);
  const [pdfThumbnailIsLoading, setPdfThumbnailIsLoading] = useState(false);

  const { getAccessTokenSilently } = useAuth0();

  const getFinalSource = async (source: string) => {
    if (/https?:\/\//.test(source)) {
      return source;
    }
    try {
      const token = await getAccessTokenSilently();
      const { data } = await axios.get(
        `${
          process.env.REACT_APP_BASE_API_URL
        }/s3-get-file-signed-url/swipoo-transactions-files/${encodeURIComponent(
          source,
        )}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      return data.signedUrl;
    } catch (e) {
      return '';
    }
  };

  useEffect(() => {
    (async () => {
      const finalSource = await getFinalSource(uri);

      return setParsedSource(finalSource);
    })();
  }, [uri]);

  const switchPreview = () => setShowPreview((prev) => !prev);

  const isPdf = useMemo(
    () => parsedSource?.includes('.pdf') || parsedSource?.includes('.PDF'),
    [parsedSource],
  );

  useEffect(() => {
    if (isPdf) {
      (async () => {
        setPdfThumbnailIsLoading(true);
        const token = await getAccessTokenSilently();
        const pdfImages = await pdfToImg(token, parsedSource);
        if (type === FILE_TYPE.DOI_BACK && pdfImages.length > 1) {
          setPdfThumbnail(pdfImages[1]);
        } else {
          setPdfThumbnail(pdfImages[0]);
        }
        return setPdfThumbnailIsLoading(false);
      })();
    }
  }, [isPdf]);

  return (
    <div role="button" onClick={switchPreview} tabIndex={0} aria-hidden="true">
      <FsLightbox
        toggler={showPreview}
        sources={
          [
            isPdf ? (
              <iframe
                title="pdf"
                src={parsedSource}
                width="1920px"
                height="1080px"
                allow="autoplay; fullscreen"
                allowFullScreen
              />
            ) : (
              parsedSource
            ),
          ] as unknown as string[]
        }
        types={isPdf ? undefined : ['image']}
      />
      <div className="mx-4 my-4 aspect-h-1 aspect-w-1 w-full flex-shrink-0 overflow-hidden rounded-lg sm:aspect-none sm:h-40 sm:w-40">
        {isPdf && pdfThumbnailIsLoading ? (
          <div className="flex justify-center items-center h-full">
            <Spinner color="text-blue-700" size={6} marginTop={0} />
          </div>
        ) : (
          <img
            src={isPdf ? pdfThumbnail : parsedSource}
            alt={alt}
            className="h-full w-full object-cover object-center sm:h-full sm:w-full"
          />
        )}
      </div>
    </div>
  );
}
