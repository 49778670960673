import { useContext, useEffect, useState } from 'react';
import { Dialog } from '@headlessui/react';
import { ClipboardCheckIcon } from '@heroicons/react/outline';
import { XCircleIcon, XIcon } from '@heroicons/react/solid';

import { useServices } from '../services';
import BaseModal from './BaseModal';
import { transferDetailsContext } from '../context/transfer-details.context';

export default function EditorProvisionalModal({ isOpen, setIsOpen }) {
  const [receiverRole, setReceiverRole] = useState('');

  const { transfer, forceUpdate } = useContext(transferDetailsContext);
  const { transaction, buyer, intermediary } = transfer;

  const isPro = Boolean(intermediary?.representative);

  const [file, setFile] = useState(null);
  const [loading, setLoader] = useState(false);
  const [error, setError] = useState(false);

  const { uploadFile } = useServices();

  const handleChangeReceiverRole = (e) => setReceiverRole(e.target.value);

  const resetEditorModal = () => {
    setReceiverRole('');
    setError(false);
    setFile(null);
    setLoader(false);
  };

  const uploadFileEntry = async () => {
    if (!file) {
      setError(true);
    }

    try {
      setLoader(true);
      if (!isPro) {
        await uploadFile(
          transaction.id,
          file,
          'provisional',
          intermediary?.representative
            ? intermediary?.representative.email
            : intermediary?.email,
        );
      } else if (receiverRole === 'intermediary') {
        await uploadFile(
          transaction.id,
          file,
          'provisional',
          intermediary?.representative
            ? intermediary?.representative.email
            : intermediary?.email,
        );
      } else if (receiverRole === 'buyer') {
        await uploadFile(
          transaction.id,
          file,
          'provisional',
          buyer?.representative ? buyer?.representative.email : buyer?.email,
        );
      }
      resetEditorModal();
      setIsOpen(false);
      forceUpdate();
    } catch (e) {
      setError(true);
    }
  };

  const onChangeFile = (e) => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  useEffect(() => {
    resetEditorModal();
  }, []);

  return (
    <BaseModal
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
        resetEditorModal();
      }}
      overflowVisible={false}
    >
      {error ? (
        <div className="rounded-md bg-red-50 p-4">
          <div className="flex">
            <div className="shrink-0">
              <XCircleIcon
                className="h-5 w-5 text-red-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <p className="text-sm font-medium text-red-800">
                Error al editar Permiso de circulación provisional
              </p>
            </div>
            <div className="ml-auto pl-3">
              <div className="-mx-1.5 -my-1.5">
                <button
                  type="button"
                  onClick={() => {
                    setIsOpen(false);
                    resetEditorModal();
                  }}
                  className="inline-flex bg-red-50 rounded-md p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-50 focus:ring-red-600"
                >
                  <span className="sr-only">Dismiss</span>
                  <XIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="bg-white px-6 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div>
              <div className="mt-3 text-center sm:mt-0 sm:text-left">
                <Dialog.Title
                  as="h3"
                  className="text-md leading-6 font-medium text-gray-900"
                >
                  Editar Permiso de circulación
                </Dialog.Title>
                <div className="mt-2">
                  <div
                    className={`px-6 pt-5 pb-6 border-2 border-gray-300 ${
                      file ? 'border-solid' : 'border-dashed'
                    } rounded-md`}
                  >
                    <div className="space-y-1 text-center justify-center">
                      {file ? (
                        <ClipboardCheckIcon
                          className="h-10 w-10 text-gray-400 m-auto"
                          aria-hidden="true"
                        />
                      ) : (
                        <svg
                          className="mx-auto h-12 w-12 text-gray-400"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 48 48"
                          aria-hidden="true"
                        >
                          <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      )}
                      <div className="text-sm text-center text-gray-600">
                        {file ? (
                          <span>Archivo cargado</span>
                        ) : (
                          <label
                            htmlFor="file-upload"
                            className="cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500"
                          >
                            <span>Subir archivo</span>
                            <input
                              id="file-upload"
                              type="file"
                              className="sr-only"
                              name="provisional"
                              onChange={onChangeFile}
                              accept="image/*, application/pdf"
                            />
                          </label>
                        )}
                      </div>
                      {!file && (
                        <p className="text-xs text-gray-500">
                          PNG, JPG, PDF hasta 15 MB
                        </p>
                      )}
                    </div>
                  </div>
                  {loading && (
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      />
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      />
                    </svg>
                  )}
                  {isPro && (
                    <div className="mt-4">
                      <p className="mb-2 text-sm font-medium text-gray-700 py-2">
                        <span>Destinatario:</span>{' '}
                        <select
                          value={receiverRole}
                          onChange={handleChangeReceiverRole}
                          className="rounded-md border-gray-300 text-sm h-fit ml-4"
                        >
                          <option value="" disabled>
                            Seleccionar
                          </option>
                          <option value="buyer">Comprador</option>
                          <option value="intermediary">Intermediario</option>
                        </select>
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              className={
                isPro && !receiverRole
                  ? 'w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-300 text-base font-medium text-white hover:bg-indigo-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm'
                  : 'w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm'
              }
              onClick={uploadFileEntry}
              disabled={isPro && !receiverRole ? true : loading}
            >
              {loading && (
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  />
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  />
                </svg>
              )}
              {loading ? 'Guardando...' : 'Guardar'}
            </button>
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={() => {
                setIsOpen(false);
                resetEditorModal();
              }}
            >
              Cancelar
            </button>
          </div>
        </>
      )}
    </BaseModal>
  );
}
