import 'react-tooltip/dist/react-tooltip.css';
import React, { useContext } from 'react';
import { Tooltip } from 'react-tooltip';

import { Company } from '../../../../../types/types';
import { transferDetailsContext } from '../../../../../context/TransferDetailsContext/transferDetailsContext';
import { shipmentsContext } from '../../../../../context/ShipmentsContext/shipmentsContext';
import { userInfoContext } from '../../../../../context/UserInfoContext/UserInfoContext';

interface IObjectKeys {
  [key: string]: Tab;
}

export type Tab = {
  id: keyof TabsType;
  title: string;
  icon: JSX.Element;
};

export interface TabsType extends IObjectKeys {
  information?: Tab;
  documents?: Tab;
  digitalSignature?: Tab;
  taxes?: Tab;
  shipment?: Tab;
  comments?: Tab;
}

const getTooltipId = (key: keyof TabsType) => {
  if (key === 'shipment') return 'shipment-tooltip';
  return '';
};

export default function Tabs({
  currentTab,
  handleChangeTab,
  tabs,
}: {
  currentTab: Tab;
  tabs: TabsType;
  handleChangeTab: (tabId: keyof TabsType) => () => void;
}) {
  const { transfer } = useContext(transferDetailsContext);
  const { companyInfo } = useContext(userInfoContext);
  const { shipments, isShipmentLoading } = useContext(shipmentsContext);

  const shipment = shipments?.[0];

  const shipmentDataIsComplete = Boolean(
    shipment?.delivery?.street &&
      shipment?.delivery?.city &&
      shipment?.delivery?.state &&
      shipment?.delivery?.zipCode &&
      shipment?.delivery?.country &&
      shipment?.receiver?.name &&
      shipment?.receiver?.email &&
      shipment?.receiver?.phone &&
      shipment?.receiver?.doi,
  );

  const showIncompleteDeliveryInformationAlert = (key: keyof TabsType) =>
    key === 'shipment' &&
    shipment &&
    !isShipmentLoading &&
    shipment.status === 'CREATED' &&
    !shipmentDataIsComplete;

  const isTaxesTabDisabled =
    transfer?.operationType === 'B2B' || transfer?.operationType === 'P2B';

  const isMechanicalWarrantyDisabled =
    (transfer?.seller as Company)?.nif !== companyInfo?.nif;

  return (
    <div className="w-full border-b gap-x-8 flex" id="tabs">
      {Object.keys(tabs)
        .filter((tab) => !(tab === 'taxes' && isTaxesTabDisabled))
        .filter(
          (tab) =>
            !(tab === 'mechanicalWarranty' && isMechanicalWarrantyDisabled),
        )
        .map((key: keyof TabsType) => (
          <div
            key={tabs[key].id}
            className={`flex gap-x-2 items-center ${
              currentTab.id === tabs[key].id
                ? 'border-b-2 text-blue-600 border-b-blue-600/100'
                : 'text-gray-500'
            }`}
          >
            {tabs[key].icon}
            {showIncompleteDeliveryInformationAlert(key) && (
              <Tooltip
                style={{
                  marginTop: '0',
                }}
                id="shipment-tooltip"
                place="top"
                content="Faltan datos de envío"
              />
            )}
            <div data-tooltip-id={getTooltipId(key)}>
              <button
                id={`${tabs[key].id}-tab`}
                data-testid={`${tabs[key].id}-tab`}
                key={tabs[key].id}
                data-tabid={tabs[key].id}
                onClick={handleChangeTab(tabs[key].id)}
                type="button"
                className="flex gap-x-2 pb-2 items-center text-sm cursor-pointer"
              >
                {tabs[key].title}{' '}
                {showIncompleteDeliveryInformationAlert(key) && (
                  <div className="rounded-full w-2 h-2 bg-red-500" />
                )}
              </button>
            </div>
          </div>
        ))}
    </div>
  );
}
