import React, { useState, useCallback } from 'react';
import { CheckCircleIcon } from '@heroicons/react/solid';

import Document from './Document';
import { ENTITY_TYPES } from '../../../../../helpers/enums';
import UploadFileModal from '../../../../../components/UploadFileModal';
import { useServices } from '../../../../../services';
import ExtraFiles from '../../../../../components/ExtraFiles';
import trimAllStrings from '../../../../../helpers/trimAllStrings';
import containsNullUndefinedOrEmptyString from '../../../../../helpers/checkNullOrUndefinedOrEmptyString';

export default function DocumentsSection({
  tramit,
  tramitCode,
  forceUpdate,
  editTramit,
  documents,
  operationType,
}) {
  const [modalType, setModalType] = useState('');
  const [hasProcedureA9Error, setHasProcedureA9Error] = useState(false);
  const [hasProcedureA9Success, setHasProcedureA9Success] = useState(false);
  const [isLoadingA9Procedure, setIsLoadingA9Procedure] = useState(false);
  const [hasMissingData, setHasMissingData] = useState(false);

  const { sendProcedureToA9 } = useServices();

  const { agent, buyer, seller, car, bate } = tramit;

  const handleSendProcedureToA9 = useCallback(async () => {
    try {
      let data = {};
      if (operationType === 'P2B') {
        data = {
          seller: {
            nif: seller.nif,
            legalName: seller.legalName,
            province: seller.fiscalAddressProvince,
            city: seller.fiscalAddressCity,
            address: seller.fiscalAddressAddress,
            zipCode: seller.fiscalAddressZipCode,
            representative: {
              dni: seller.representative?.dni,
              name: seller.representative?.name,
              surname: seller.representative?.surname,
            },
          },
          buyer: {
            iae: '6541', // Añadir campo en los autónomos.
            dni: buyer.dni,
            sex: buyer.sex,
            name: buyer.name,
            surname: buyer.surname,
            province: buyer.province,
            city: buyer.city,
            birthDate: buyer.birthDate,
            address: buyer.address,
            zipCode: buyer.zipCode,
          },
          agent: {
            nif: agent.nif,
          },
          bate: {
            bateCode: bate.bateCode,
            dgtFeeNumber: bate.dgtFeeNumber,
          },
          car: {
            enrollmentDate: car.enrollmentDate,
            plate: car.plate,
            serviceType: car.serviceType,
            isMoto: car.isMoto,
          },
        };
      } else if (operationType === 'B2B') {
        data = {
          seller: {
            nif: seller.nif,
            legalName: seller.legalName,
            province: seller.fiscalAddressProvince,
            city: seller.fiscalAddressCity,
            address: seller.fiscalAddressAddress,
            zipCode: seller.fiscalAddressZipCode,
            representative: {
              dni: seller.representative?.dni,
              name: seller.representative?.name,
              surname: seller.representative?.surname,
            },
          },
          buyer: {
            iae: '6541', // Añadir campo en las empresas.
            nif: buyer.nif,
            legalName: buyer.legalName,
            province: buyer.fiscalAddressProvince,
            city: buyer.fiscalAddressCity,
            address: buyer.fiscalAddressAddress,
            zipCode: buyer.fiscalAddressZipCode,
            representative: {
              dni: buyer.representative?.dni,
              name: buyer.representative?.name,
              surname: buyer.representative?.surname,
            },
          },
          agent: {
            nif: agent.nif,
          },
          bate: {
            bateCode: bate.bateCode,
            dgtFeeNumber: bate.dgtFeeNumber,
          },
          car: {
            enrollmentDate: car.enrollmentDate,
            plate: car.plate,
            serviceType: car.serviceType,
            isMoto: car.isMoto,
          },
        };
      } else if (operationType === 'P2P') {
        data = {
          seller: {
            dni: seller.dni,
            sex: seller.sex,
            name: seller.name,
            surname: seller.surname,
            province: seller.province,
            city: seller.city,
            birthDate: seller.birthDate,
            address: seller.address,
            zipCode: seller.zipCode,
          },
          buyer: {
            iae: '6541', // Añadir campo en los autónomos.
            dni: buyer.dni,
            sex: buyer.sex,
            name: buyer.name,
            surname: buyer.surname,
            province: buyer.province,
            city: buyer.city,
            birthDate: buyer.birthDate,
            address: buyer.address,
            zipCode: buyer.zipCode,
          },
          agent: {
            nif: agent.nif,
          },
          bate: {
            bateCode: bate.bateCode,
            dgtFeeNumber: bate.dgtFeeNumber,
          },
          car: {
            enrollmentDate: car.enrollmentDate,
            plate: car.plate,
            serviceType: car.serviceType,
            isMoto: car.isMoto,
          },
        };
      } else if (operationType === 'B2P') {
        data = {
          seller: {
            dni: seller.dni,
            sex: seller.sex,
            name: seller.name,
            surname: seller.surname,
            province: seller.province,
            city: seller.city,
            birthDate: seller.birthDate,
            address: seller.address,
            zipCode: seller.zipCode,
          },
          buyer: {
            iae: '6541', // Añadir campo en las empresas.
            nif: buyer.nif,
            legalName: buyer.legalName,
            province: buyer.fiscalAddressProvince,
            city: buyer.fiscalAddressCity,
            address: buyer.fiscalAddressAddress,
            zipCode: buyer.fiscalAddressZipCode,
            representative: {
              dni: buyer.representative?.dni,
              name: buyer.representative?.name,
              surname: buyer.representative?.surname,
            },
          },
          agent: {
            nif: agent.nif,
          },
          bate: {
            bateCode: bate.bateCode,
            dgtFeeNumber: bate.dgtFeeNumber,
          },
          car: {
            enrollmentDate: car.enrollmentDate,
            plate: car.plate,
            serviceType: car.serviceType,
            isMoto: car.isMoto,
          },
        };
      } else {
        throw Error('Invalid Bate operation type');
      }

      // Si es CT se liquida en el A9 y necesita más datos.
      if (buyer.fiscalAddressState === 'CT') {
        data.car = {
          ...data.car,
          cc: car.cc,
          brand: car.brand,
          model: car.model,
          frameNumber: car.frameNumber,
        };
      }
      const hasNullOrUndefiend = containsNullUndefinedOrEmptyString(data);
      if (hasNullOrUndefiend) {
        setHasMissingData(true);
        return;
      }
      const trimmedData = trimAllStrings(data);
      setIsLoadingA9Procedure(true);
      await sendProcedureToA9({
        procedureType: ENTITY_TYPES.BATE,
        operationType,
        data: trimmedData,
      });
      setHasProcedureA9Error(false);
      setHasProcedureA9Success(true);
    } catch {
      setHasProcedureA9Error(true);
    } finally {
      setIsLoadingA9Procedure(false);
    }
  }, [seller, buyer, agent, car, bate, sendProcedureToA9]);

  return (
    <>
      <UploadFileModal
        entity={tramit.bate}
        modalType={modalType}
        setModalType={setModalType}
        entityKey={tramitCode}
        editEntity={editTramit}
        forceUpdate={forceUpdate}
      />
      <div className="sm:col-span-2">
        <dt className="text-sm font-medium text-gray-500">Documentos</dt>
        <dd className="mt-1 text-sm text-gray-900">
          <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
            {documents.map((document) => (
              <Document
                key={document.key}
                documentKey={document.key}
                title={document.title}
                file={tramit?.bate?.[document.key]}
                setModalType={setModalType}
              />
            ))}
            <ExtraFiles tramitType={ENTITY_TYPES.BATE} entity={tramit.bate} />
          </ul>
          <div className="flex items-center mt-4">
            <button
              type="button"
              disabled={isLoadingA9Procedure}
              className="text-white bg-[#CD8383] hover:bg-[#C67070] rounded-md px-4 py-1 text-lg flex items-center gap-2"
              onClick={handleSendProcedureToA9}
            >
              {isLoadingA9Procedure ? 'Enviando...' : 'Enviar trámite al A9'}{' '}
              <img
                alt="logo A9"
                src="https://static.a9trafico.com/static/img/a9_logo.51283c3f500c.png"
                className="h-6"
              />
            </button>
            {hasProcedureA9Error && (
              <span className="ml-2 text-sm text-red-600">
                Error enviando trámite al A9
              </span>
            )}
            {hasMissingData && (
              <span className="ml-2 text-sm text-red-600">
                Faltan datos por completar
              </span>
            )}
            {hasProcedureA9Success && (
              <span className="flex items-center ml-2 text-sm text-green-600">
                Enviado correctamente
                <CheckCircleIcon className="ml-2 h-6 text-green-600" />
              </span>
            )}
          </div>
        </dd>
      </div>
    </>
  );
}
