import React, { useContext } from 'react';
import {
  BateTramit,
  RegistrationTramit,
  TemplateType,
  Transfer,
} from '../../../../types/types';

import useTemplates from './hooks/useTemplates';
import TemplatePicker from './components/TemplatePicker';
import TemplatePreview from './components/TemplatePreview';
import { digitallySignContext } from '../../../../context/DigitallySignContext/DigitallySignContext';

export type Template = {
  id: string;
  name: string;
  modifiedTime: string;
  thumbnailLink: string;
  type: TemplateType;
  default: boolean;
};

type ChooseTemplateStepProps = Readonly<{
  procedure: Transfer | BateTramit | RegistrationTramit;
  templateType: TemplateType;
  readonly counterpartType: 'SELLER' | 'BUYER';
}>;

export default function ChooseTemplateStep({
  procedure,
  templateType,
  counterpartType,
}: ChooseTemplateStepProps) {
  const { setGeneratedDocumentId } = useContext(digitallySignContext);
  const {
    templates,
    selectedTemplateId,
    setSelectedTemplateId,
    injectTemplate,
    injectedDocumentId,
  } = useTemplates(templateType);

  return (
    <section className="flex items-center gap-9 h-[640px]">
      <TemplatePicker
        templates={templates}
        counterpartType={counterpartType}
        selectedTemplateId={selectedTemplateId}
        setSelectedTemplateId={setSelectedTemplateId}
        setSelectedDocId={setGeneratedDocumentId}
        procedure={procedure}
        injectTemplate={injectTemplate}
        injectedDocumentId={injectedDocumentId}
        templateType={templateType}
      />
      {selectedTemplateId && (
        <TemplatePreview selectedTemplateId={selectedTemplateId} />
      )}
    </section>
  );
}
