import React from 'react';

import classNames from '../helpers/classNames';

export type Tab = {
  id: keyof TabsType;
  title: string;
  icon: JSX.Element;
};

interface IObjectKeys {
  [key: string]: Tab;
}

export interface TabsType extends IObjectKeys {
  information?: Tab;
  documents?: Tab;
}

export default function Tabs({
  currentTab,
  handleChangeTab,
  tabs,
}: Readonly<{
  currentTab: Tab;
  tabs: TabsType;
  handleChangeTab: (tabId: keyof TabsType) => () => void;
}>) {
  return (
    <div className="w-full border-b gap-x-8 flex" id="bate-tabs">
      {Object.keys(tabs).map((key: keyof TabsType) => (
        <div
          key={tabs[key].id}
          className={`flex gap-x-2 items-center ${
            currentTab.id === tabs[key].id
              ? 'border-b-2 text-blue-600 border-b-blue-600/100'
              : 'text-gray-500'
          }`}
        >
          {tabs[key].icon}
          <button
            id={`${tabs[key].id}-tab`}
            data-testid={`${tabs[key].id}-tab`}
            key={tabs[key].id}
            data-tabid={tabs[key].id}
            onClick={handleChangeTab(tabs[key].id)}
            type="button"
            className={classNames(
              currentTab.id === tabs[key].id
                ? 'text-blue-600 border-b-blue-600/100'
                : 'text-gray-500',
              'flex gap-x-2 pb-2 items-center text-sm cursor-pointer',
            )}
          >
            {tabs[key].title}
          </button>
        </div>
      ))}
    </div>
  );
}
